@font-face {
  font-family: Barlow;
  src: url('./assets/fonts/Barlow-Regular.ttf');
}
@font-face {
  font-family: BarlowMedium;
  src: url('./assets/fonts/Barlow-Medium.ttf');
}
@font-face {
  font-family: BarlowBold;
  src: url('./assets/fonts/Barlow-ExtraBold.ttf');
}
body {
  font-family: 'Barlow';
}

.notification {
  font-family: 'Gill Sans', sans-serif;
  letter-spacing: 1.2px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.leaflet-right {
  display: none;
}

.leaflet-top {
  bottom: 0;
  top: auto;
}
.leaflet-left {
  right: 0;
  left: auto;
}

.leaflet-bar {
  margin-right: 10px;
  margin-bottom: 10px;
}
.leaflet-popup-content-wrapper {
  max-width: 250px;
}

.leaflet-div-icon {
  width: 0px !important;
  height: 0px !important;
  border: none !important;
}

.valueMakerLastLocation {
  margin: 0 !important;
}
.leaflet-popup-content-wrapper {
  box-shadow: 0 3px 14px rgb(9, 9, 9, 0.2) !important;
}

.autocomplelteCustom {
  height: 30px;
  min-width: 140px;
  font-weight: 600;
}

.autocomplelteCustom > div > div {
  height: 30px;
}

.autocomplelteCustom > label {
  transform: translate(14px, 0px) scale(1);
}

.autocomplelteCustom > div > div > input {
  padding: 0px !important;
}

.keyboardDatePickerCustom {
  max-width: 140px;
  padding: 0;
}

.keyboardDatePickerCustom > div > input {
  padding: 0px 5px;
  height: 30px;
}

.keyboardDatePickerCustom > div > div > button {
  padding: 0px;
}
